import { Injectable } from '@angular/core';
import { User } from '../../users/user.model';
import * as _ from 'lodash';

@Injectable()
export class AccessService {
    _user: User;

    // Defining relationship between roles (key - role, value - array of children roles)
    hierarchy = {
        ROLE_ADMIN: ['ROLE_ADMIN', 'ROLE_ASSISTANT'],
        ROLE_ASSISTANT: ['ROLE_ASSISTANT']
    };


    constructor() {
        this._user = this.user;
    }

    set user(user: User) {
        this._user = user;
        localStorage.setItem('accessUser', JSON.stringify(this.user));
    }

    get user() {
        return this._user || JSON.parse(localStorage.getItem('accessUser')) || null;
    }

    get role() {
        return this._user.role;
    }

    // Checking if logged user can do some action with user passed in params (according to their roles and hierarchy relationship)
    // If self = true, then logged user can do some action with children roles and himself, else only with children */

    hierarchicAccess(user: User, self: boolean = true): Boolean {
        return _.includes(this.hierarchy[this.role], user.role) || (self && this.user.id === user.id);
    }

    // Checking if logged user role responds to array of roles passed in params
    roleResponds(roles: String[]) {
        return (roles.indexOf(this.role) >= 0);
    }

    reset() {
        this.user = null;
        localStorage.removeItem('accessUser');
    }
}


