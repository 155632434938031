import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './language.model';
import { ApiService } from '../core/_services/api.service';

@Injectable()
export class LanguagesService {
    selected: Language;
    languages: Language[] = [
        new Language('nl', 'nl_NL', 'Dutch'),
        new Language('fr', 'fr_BE', 'French'),
        new Language('nl-be', 'nl_BE', 'Flemish Dutch'),
        new Language('en', 'en_EN', 'English')
    ];
    appLanguages: Language[] = [
        new Language('nl', 'nl_NL', 'Dutch'),
        new Language('nl-be', 'nl_BE', 'Flemish Dutch'),
        new Language('fr', 'fr_BE', 'French')
    ];

    constructor(private $translate: TranslateService, private $api: ApiService) {
        this.initLanguage();
    }

    initLanguage() {
        let storedLanguageCode = localStorage.getItem('language');
        if (storedLanguageCode) {
            this.setLanguage(storedLanguageCode);
        } else {
            this.setDefault();
        }
    }

    setDefault() {
        const defaultLanguge = 'nl';
        this.$translate.setDefaultLang(defaultLanguge);
        this.setLanguage(defaultLanguge);
    }

    setLanguage(languageCode: string) {
        this.$translate.use(languageCode);
        this.selected = this.languages.find(language => language.code === languageCode);
        this.$api.setLanguage(this.selected.fullCode);
        localStorage.setItem('language', languageCode);
    }
}

