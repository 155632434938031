<section id="login" class="auth-page">
    <div class="container-fluid">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card gradient-light-gray text-center width-400">
                    <div class="card-img">
                        <img alt="element 06" class="mb-1" src="assets/img/logos/logo.png" width="190">
                    </div>
                    <div class="card-body">
                        <div class="card-block">
                            <h2>Login</h2>
                            <form novalidate="" (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <input type="text"
                                               formControlName="username"
                                               class="form-control"
                                               name="username"
                                               placeholder="Username">
                                        <small
                                            class="form-text text-muted danger"
                                            *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched)">
                                            Username is incorrect
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-md-12">
                                        <input type="password"
                                               formControlName="password"
                                               class="form-control"
                                               name="inputPass"
                                               placeholder="Password"
                                               required>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <button
                                            [disabled]="!loginForm.valid"
                                            type="submit"
                                            class="btn btn-primary btn-block btn-raised">Submit
                                        </button>
                                    </div>
                                    <div class="col-md-12">
                                        <small
                                            class="form-text text-muted danger"
                                            *ngIf="errorMessage" [innerHtml]="errorMessage">
                                        </small>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!--<div class="card-footer grey">-->
                        <!--<div class="float-left"><a (click)="onForgotPassword()">Recover Password</a></div>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->
