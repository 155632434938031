import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/_services/api.service';
import { User } from '../users/user.model';
import { AccessService } from '../core/_services/access.service';

@Injectable()
export class AuthService {
    _token: string;

    constructor(private $api: ApiService,
                private $access: AccessService,
                private router: Router){
        this._token = this.token;
    }

    login(user: any){
        return this.$api.post('login', user)
            .then(data => {
                this.setData(data);
            });
    }

    logout(){
        this.reset();
        this.router.navigate(['/login']);
    }

    setData(data){
        this.token = data.token;
        this.$access.user = new User(data.user);
    }

    get token(){
        return this._token || localStorage.getItem('token') || null;
    }

    set token(token){
        this._token = token;
        localStorage.setItem('token', token);
    }

    reset(){
        this.token = null;
        localStorage.removeItem('token');
        this.$access.reset();
    }

    isAuthenticated(){
        return !!this.token;
    }
}

