/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ngx-progressbar/core/ngx-progressbar-core.ngfactory";
import * as i2 from "@ngx-progressbar/core";
import * as i3 from "@angular/router";
import * as i4 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-progress", [["role", "progressbar"]], [[1, "spinnerPosition", 0], [1, "dir", 0], [1, "thick", 0], [1, "fixed", 0]], null, null, i1.View_NgProgressComponent_0, i1.RenderType_NgProgressComponent)), i0.ɵdid(1, 770048, null, 0, i2.NgProgressComponent, [i2.NgProgress], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).spinnerPosition; var currVal_1 = i0.ɵnov(_v, 1).direction; var currVal_2 = i0.ɵnov(_v, 1).thick; var currVal_3 = i0.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i4.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
