/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./navigation-item/navigation-item.component.ngfactory";
import * as i2 from "./navigation-item/navigation-item.component";
import * as i3 from "@angular/router";
import * as i4 from "../_services/sidebar.service";
import * as i5 from "@angular/common";
import * as i6 from "./sidebar.component";
import * as i7 from "../../core/_services/access.service";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: { "animation": [{ type: 7, name: "slide", definitions: [{ type: 0, name: "down", styles: { type: 6, styles: { height: "*", display: "block" }, offset: null }, options: undefined }, { type: 0, name: "up", styles: { type: 6, styles: { height: 0, display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "up => down", animation: { type: 4, styles: null, timings: "300ms" }, options: null }, { type: 1, expr: "down => up", animation: { type: 4, styles: null, timings: "300ms" }, options: null }], options: {} }] } });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-navigation-item", [], null, null, null, i1.View_NavigationItemComponent_0, i1.RenderType_NavigationItemComponent)), i0.ɵdid(1, 114688, null, 0, i2.NavigationItemComponent, [i3.Router, i0.ViewContainerRef, i0.ChangeDetectorRef, i4.SidebarService], { menuItem: [0, "menuItem"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "sidebar-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "logo clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "a", [["class", "logo-text float-left"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "logo-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["src", "assets/img/logos/logo-sidebar.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "a", [["class", "nav-toggle d-none d-sm-none d-md-none d-lg-block"], ["id", "sidebarToggle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "i", [["class", "toggle-icon"], ["data-toggle", "expanded"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleSidebar($event, !_co.isNavExpand) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(11, 1), (_l()(), i0.ɵeld(12, 0, null, null, 1, "a", [["class", "nav-close d-block d-md-block d-lg-none d-xl-none"], ["id", "sidebarClose"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "i", [["class", "ft-x"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onHideSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "div", [["class", "sidebar-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "div", [["class", "nav-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 2, "ul", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i0.ɵdid(18, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "/"); _ck(_v, 3, 0, currVal_2); var currVal_3 = "toggle-icon"; var currVal_4 = _ck(_v, 11, 0, (_co.isNavExpand ? "ft-toggle-right" : "ft-toggle-left")); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = _co.menuItems; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, [[null, "mouseenter"], [null, "mouseleave"], ["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onMouseenter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseleave() !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:click" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 114688, null, 0, i6.SidebarComponent, [i3.Router, i4.SidebarService, i7.AccessService, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i0.ɵccf("app-sidebar", i6.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
