<div class="wrapper sidebar-lg" [ngClass]="{'nav-collapsed':!isNavExpand, 'menu-collapsed':!isMenuExpand}">
    <div class="app-sidebar"
         [ngClass]="{'hide-sidebar': isHideSidebar}"
         data-active-color="white"
         data-background-color="nutricia">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>
