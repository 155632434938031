export class User {
    public id: any;
    public username: string;
    public email: string;
    public role: 'ROLE_ADMIN' | 'ROLE_ASSISTANT';
    public isActive: boolean;


    constructor(data) {
        this.role = data.role;
        this.id = data.id;
        this.email = data.email;
        this.username = data.username;
        this.isActive = data.isActive || false;
    }
}
