import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'sidebar_menu.dashboard',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        access: '*'
    },
    {
        path: '/users',
        title: 'sidebar_menu.users',
        icon: 'ft-users',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: [
            {
                path: '/users/create',
                title: 'sidebar_menu.create_user',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: 'ROLE_ADMIN'
            }
        ]
    },
    {
        path: '/articles',
        title: 'sidebar_menu.articles',
        icon: 'ft-layers',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: ['ROLE_ADMIN', 'ROLE_ASSISTANT'],
        submenu: [
            {
                path: '/articles/signup',
                title: 'sidebar_menu.signup_article',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: ['ROLE_ADMIN', 'ROLE_ASSISTANT']
            }
        ]
    },
    {
        path: '',
        title: 'sidebar_menu.order_limits',
        icon: 'ft-clipboard',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: [
            {
                path: '/limits/article-groups',
                title: 'sidebar_menu.article_groups',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: 'ROLE_ADMIN'
            },
            {
                path: '/limits/articles',
                title: 'sidebar_menu.articles',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: 'ROLE_ADMIN'
            },
            {
                path: '/limits/exceedances',
                title: 'sidebar_menu.exceedances',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: 'ROLE_ADMIN'
            }
        ]
    },
    {
        path: '/article-visibility',
        title: 'sidebar_menu.article_visibility',
        icon: 'ft-eye',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: [
            {
                path: '/article-visibility/create',
                title: 'sidebar_menu.create_new',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: 'ROLE_ADMIN'
            }
        ]
    },
    {
        path: '/po-numbers',
        title: 'sidebar_menu.po_numbers',
        icon: 'ft-hash',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: [
            {
                path: '/po-numbers/create',
                title: 'sidebar_menu.create_new',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                access: 'ROLE_ADMIN'
            }
        ]
    },
    {
        path: '/customers',
        title: 'sidebar_menu.addressing_po',
        icon: 'ft-shuffle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: []
    },
    {
        path: '/order-report',
        title: 'sidebar_menu.order_report',
        icon: 'ft-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: ['ROLE_ADMIN', 'ROLE_ASSISTANT'],
        submenu: []
    },
    {
        path: '/justifications',
        title: 'sidebar_menu.justifications',
        icon: 'ft-align-left',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: []
    },
    {
        path: '/terms-conditions',
        title: 'sidebar_menu.terms_conditions',
        icon: 'ft-check-circle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        access: 'ROLE_ADMIN',
        submenu: []
    },
];
