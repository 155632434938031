import { AuthGuard } from '../auth/auth.guard';
const ɵ0 = () => import("./../auth/auth.module.ngfactory").then(m => m.AuthModuleNgFactory);
export const PageRoutes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: ɵ0,
        canLoad: [AuthGuard]
    }
];
export { ɵ0 };
