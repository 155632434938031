import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {MatMenuModule, MatTabsModule} from "@angular/material";

@NgModule({
    imports: [
        CommonModule,
        MatTabsModule,
        MatMenuModule,
    ],
    exports: [
        CommonModule,
        TranslateModule,
        MatTabsModule,
        MatMenuModule,
    ]
})
export class LanguagesModule {
}

