import {NgModule} from '@angular/core';
import {MatMenuModule} from "@angular/material";
import {CommonModule} from '@angular/common';
import {BackofficeLayoutComponent} from './backoffice-layout.component';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavigationItemComponent} from './sidebar/navigation-item/navigation-item.component';
import {RouterModule} from '@angular/router';

import {LanguagesModule} from '../languages/languages.module';
import { LanguagesComponent } from '../languages/languages.component';
import {SidebarService} from './_services/sidebar.service';
import {WindowRef} from './_services/window-ref';
import {ToggleFullscreenDirective} from '../core/_directives/toggle-fullscreen.directive';
import { LanguagesService } from '../languages/languages.service';


@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        RouterModule,
        LanguagesModule,
    ],
    declarations: [
        BackofficeLayoutComponent,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        NavigationItemComponent,
        ToggleFullscreenDirective,
        LanguagesComponent
    ],
    entryComponents: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        BackofficeLayoutComponent
    ],
    providers: [
        SidebarService,
        LanguagesService,
        WindowRef,
    ]
})
export class BackofficeLayoutModule {
}
