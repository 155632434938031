/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/_directives/toggle-fullscreen.directive";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i8 from "@angular/common";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../languages/languages.component.ngfactory";
import * as i11 from "../../languages/languages.component";
import * as i12 from "../../languages/languages.service";
import * as i13 from "./navbar.component";
import * as i14 from "../../auth/auth.service";
import * as i15 from "../../core/_services/access.service";
import * as i16 from "@angular/router";
import * as i17 from "../_services/sidebar.service";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "nav", [["class", "navbar navbar-expand-lg navbar-light bg-faded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 38, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["class", "navbar-toggle d-lg-none float-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 30, "div", [["class", "navbar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 29, "div", [["class", "collapse navbar-collapse"], ["id", "navbarSupportedContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 28, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "li", [["class", "nav-item mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "a", [["appToggleFullscreen", ""], ["class", "nav-link"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.ToggleClass() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i2.ToggleFullscreenDirective, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [["class", "d-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["fullscreen"])), (_l()(), i1.ɵeld(18, 0, null, null, 18, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 16777216, null, null, 4, "a", [["aria-haspopup", "true"], ["class", "nav-link dropdown-toggle mat-menu-trigger"]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 1196032, null, 0, i3.MatMenuTrigger, [i4.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i5.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "ft-user font-medium-3 blue-grey darken-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "p", [["class", "d-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["User Settings"])), (_l()(), i1.ɵeld(24, 0, null, null, 12, "mat-menu", [], null, null, null, i7.View__MatMenu_0, i7.RenderType__MatMenu)), i1.ɵdid(25, 1294336, [["userMenu", 4]], 3, i3._MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 1, { _allItems: 1 }), i1.ɵqud(603979776, 2, { items: 1 }), i1.ɵqud(603979776, 3, { lazyContent: 0 }), i1.ɵprd(2048, null, i3.MatMenu, null, [i3._MatMenu]), i1.ɵprd(2048, null, i3.MAT_MENU_PANEL, null, [i3.MatMenu]), (_l()(), i1.ɵeld(31, 0, null, 0, 5, "button", [["class", "dropdown-item mat-menu-item"], ["mat-menu-item", ""], ["type", "button"]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 32)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onLogout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_MatMenuItem_0, i7.RenderType_MatMenuItem)), i1.ɵdid(32, 180224, [[1, 4], [2, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i8.DOCUMENT, i6.FocusMonitor, [2, i3.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵeld(33, 0, null, 0, 0, "i", [["class", "ft-power mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, 0, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(35, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(37, 0, null, null, 2, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 1, "app-languages", [], null, null, null, i10.View_LanguagesComponent_0, i10.RenderType_LanguagesComponent)), i1.ɵdid(39, 114688, null, 0, i11.LanguagesComponent, [i12.LanguagesService], null, null)], function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 25); _ck(_v, 20, 0, currVal_2); _ck(_v, 25, 0); _ck(_v, 39, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.toggleClass, " font-medium-3 blue-grey darken-4"); _ck(_v, 15, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 20).menuOpen || null); _ck(_v, 19, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 32).role; var currVal_4 = i1.ɵnov(_v, 32)._highlighted; var currVal_5 = i1.ɵnov(_v, 32)._triggersSubmenu; var currVal_6 = i1.ɵnov(_v, 32)._getTabIndex(); var currVal_7 = i1.ɵnov(_v, 32).disabled.toString(); var currVal_8 = (i1.ɵnov(_v, 32).disabled || null); _ck(_v, 31, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform("buttons.logout")); _ck(_v, 35, 0, currVal_9); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i13.NavbarComponent, [i14.AuthService, i15.AccessService, i16.Router, i17.SidebarService], null, null)], null, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i13.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
