<nav class="navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item mr-2">
                        <a href="javascript:;" class="nav-link" appToggleFullscreen (click)="ToggleClass()">
                            <i class="{{toggleClass}} font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">fullscreen</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="userMenu">
                            <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">User Settings</p>
                        </a>
                        <mat-menu #userMenu>
                            <button mat-menu-item class="dropdown-item" type="button" (click)="onLogout()">
                                <i class="ft-power mr-2"></i>
                                <span>{{'buttons.logout' | translate}}</span>
                            </button>
                        </mat-menu>
                    </li>
                    <li class="nav-item">
                        <app-languages></app-languages>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
