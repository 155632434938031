<!-- Sidebar Header starts -->
<div class="sidebar-header">
    <div class="logo clearfix">
        <a [routerLink]="['/']" class="logo-text float-left">
            <div class="logo-img">
                <img src="assets/img/logos/logo-sidebar.png"/>
            </div>
        </a>
        <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle">
            <i
                    (click)="onToggleSidebar($event, !isNavExpand)"
                    [ngClass]="[isNavExpand ? 'ft-toggle-right' : 'ft-toggle-left']"
                    class="toggle-icon"
                    data-toggle="expanded"></i>
        </a>
        <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose">
            <i (click)="onHideSidebar()" class="ft-x"></i>
        </a>
    </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content">
    <div class="nav-container">
        <ul class="navigation">
            <app-navigation-item
                    *ngFor="let menuItem of menuItems;"
                    [menuItem]="menuItem"></app-navigation-item>
        </ul>
    </div>
</div>
<!-- Sidebar Content Ends -->
