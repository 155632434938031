import { Component, OnInit } from '@angular/core';
import { LanguagesService } from './languages.service';
import { Language } from './language.model';

@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html',
    styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
    languages: Language[] = [];

    constructor(public $languages: LanguagesService) {
    }

    ngOnInit() {
        this.languages = this.$languages.languages;
    }

    changeLanguage(languageCode) {
        this.$languages.setLanguage(languageCode);
    }
}
