import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../../core/_services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {

    loginForm: FormGroup;
    errorMessage: string;

    constructor(private router: Router,
                private $api: ApiService,
                private toastr: ToastrService,
                private _auth: AuthService) {

        //pondres
        //Ch4ng3m3!N0w

        this.loginForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
    }

    onSubmit() {
        this.errorMessage = '';
        this.loginForm['submitted'] = true;
        if (this.loginForm.valid) {
            this.loginUser(this.loginForm.value);
        }
    }

    loginUser(credentials) {
        this._auth.login(credentials)
            .then(() => this.router.navigate(['']))
            .catch(err => this.errorMessage = err.message || 'Server communication error');
    }
}
