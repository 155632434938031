<a [matMenuTriggerFor]="languageMenu" class="nav-link dropdown-toggle">
    <i class="ft-globe font-medium-3 blue-grey darken-4"></i>
    <p class="d-none">Languages</p>
</a>
<mat-menu #languageMenu>
    <button mat-menu-item type="button"
        *ngFor="let language of languages"
        [ngClass]="language === $languages.selected && 'active'"
        (click)="changeLanguage(language.code)"
    >
        {{ language.name }}
    </button>
</mat-menu>
