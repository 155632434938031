const ɵ0 = () => import("./../dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ1 = () => import("./../sundry/sundry.module.ngfactory").then(m => m.SundryModuleNgFactory), ɵ2 = () => import("./../users/users.module.ngfactory").then(m => m.UsersModuleNgFactory), ɵ3 = () => import("./../articles/articles.module.ngfactory").then(m => m.ArticlesModuleNgFactory), ɵ4 = () => import("./../article-visibility/article-visibility.module.ngfactory").then(m => m.ArticleVisibilityModuleNgFactory), ɵ5 = () => import("./../customers/customers.module.ngfactory").then(m => m.CustomersModuleNgFactory), ɵ6 = () => import("./../po-numbers/po-numbers.module.ngfactory").then(m => m.PoNumbersModuleNgFactory), ɵ7 = () => import("./../order-limits/order-limits.module.ngfactory").then(m => m.OrderLimitsModuleNgFactory), ɵ8 = () => import("./../justifications/justifications.module.ngfactory").then(m => m.JustificationsModuleNgFactory), ɵ9 = () => import("./../order-report/order-report.module.ngfactory").then(m => m.OrderReportModuleNgFactory);
export const BackofficeRoutes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: '',
        loadChildren: ɵ1
    },
    {
        path: 'users',
        loadChildren: ɵ2
    },
    {
        path: 'articles',
        loadChildren: ɵ3
    },
    {
        path: 'article-visibility',
        loadChildren: ɵ4
    },
    {
        path: 'customers',
        loadChildren: ɵ5
    },
    {
        path: 'po-numbers',
        loadChildren: ɵ6
    },
    {
        path: 'limits',
        loadChildren: ɵ7
    },
    {
        path: 'justifications',
        loadChildren: ɵ8
    },
    {
        path: 'order-report',
        loadChildren: ɵ9
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
