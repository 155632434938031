import { Routes } from '@angular/router';

export const BackofficeRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: '',
        loadChildren: () => import('app/sundry/sundry.module').then(m => m.SundryModule)
    },
    {
        path: 'users',
        loadChildren: () => import('app/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'articles',
        loadChildren: () => import('app/articles/articles.module').then(m => m.ArticlesModule)
    },
    {
        path: 'article-visibility',
        loadChildren: () => import('app/article-visibility/article-visibility.module').then(m => m.ArticleVisibilityModule)
    },
    {
        path: 'customers',
        loadChildren: () => import('app/customers/customers.module').then(m => m.CustomersModule)
    },
    {
        path: 'po-numbers',
        loadChildren: () => import('app/po-numbers/po-numbers.module').then(m => m.PoNumbersModule)
    },
    {
        path: 'limits',
        loadChildren: () => import('app/order-limits/order-limits.module').then(m => m.OrderLimitsModule)
    },
    {
        path: 'justifications',
        loadChildren: () => import('app/justifications/justifications.module').then(m => m.JustificationsModule)
    },
    {
        path: 'order-report',
        loadChildren: () => import('app/order-report/order-report.module').then(m => m.OrderReportModule)
    }
];
