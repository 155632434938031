/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./backoffice-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sidebar/sidebar.component.ngfactory";
import * as i4 from "./sidebar/sidebar.component";
import * as i5 from "@angular/router";
import * as i6 from "./_services/sidebar.service";
import * as i7 from "../core/_services/access.service";
import * as i8 from "./navbar/navbar.component.ngfactory";
import * as i9 from "./navbar/navbar.component";
import * as i10 from "../auth/auth.service";
import * as i11 from "./footer/footer.component.ngfactory";
import * as i12 from "./footer/footer.component";
import * as i13 from "./backoffice-layout.component";
var styles_BackofficeLayoutComponent = [i0.styles];
var RenderType_BackofficeLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackofficeLayoutComponent, data: {} });
export { RenderType_BackofficeLayoutComponent as RenderType_BackofficeLayoutComponent };
export function View_BackofficeLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "wrapper sidebar-lg"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "nav-collapsed": 0, "menu-collapsed": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "app-sidebar"], ["data-active-color", "white"], ["data-background-color", "nutricia"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "hide-sidebar": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-sidebar", [], null, [[null, "mouseenter"], [null, "mouseleave"], ["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onMouseenter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onMouseleave() !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onResize($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_SidebarComponent_0, i3.RenderType_SidebarComponent)), i1.ɵdid(9, 114688, null, 0, i4.SidebarComponent, [i5.Router, i6.SidebarService, i7.AccessService, i5.ActivatedRoute], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "sidebar-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "main-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-navbar", [], null, null, null, i8.View_NavbarComponent_0, i8.RenderType_NavbarComponent)), i1.ɵdid(13, 49152, null, 0, i9.NavbarComponent, [i10.AuthService, i7.AccessService, i5.Router, i6.SidebarService], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(18, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(20, 49152, null, 0, i12.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wrapper sidebar-lg"; var currVal_1 = _ck(_v, 3, 0, !_co.isNavExpand, !_co.isMenuExpand); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "app-sidebar"; var currVal_3 = _ck(_v, 7, 0, _co.isHideSidebar); _ck(_v, 6, 0, currVal_2, currVal_3); _ck(_v, 9, 0); _ck(_v, 18, 0); }, null); }
export function View_BackofficeLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-backoffice-layout", [], null, null, null, View_BackofficeLayoutComponent_0, RenderType_BackofficeLayoutComponent)), i1.ɵdid(1, 8503296, null, 0, i13.BackofficeLayoutComponent, [i1.ElementRef, i1.ChangeDetectorRef, i6.SidebarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackofficeLayoutComponentNgFactory = i1.ɵccf("app-backoffice-layout", i13.BackofficeLayoutComponent, View_BackofficeLayoutComponent_Host_0, {}, {}, []);
export { BackofficeLayoutComponentNgFactory as BackofficeLayoutComponentNgFactory };
