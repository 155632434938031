import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from './_services/api.service';
import { AccessService } from './_services/access.service';
import { AccessGuard } from './_guards/access.guard';
import { AuthModule } from '../auth/auth.module';
import { BackofficeLayoutModule } from '../backoffice-layout/backoffice-layout.module';
import { PageLayoutModule } from '../page-layout/page-layout.module';
import { NotificationService } from './_services/notification.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BrowserAnimationsModule,
        RouterModule,
        BackofficeLayoutModule,
        PageLayoutModule,
        AuthModule
    ],
    exports: [],
    declarations: [],
    providers: [
        ApiService,
        AccessService,
        AccessGuard,
        NotificationService
    ]
})

export class CoreModule {
}
