import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccessService } from '../_services/access.service';
import { ArticlesService } from '../../articles/_services/articles.service';
import { NotificationService } from '../_services/notification.service';

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(private router: Router, private $access: AccessService, private $notify: NotificationService) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.$access.roleResponds(route.data['roles'])) {
            return true;
        }
        this.$notify.permissionError();
        this.router.navigate(['/']);
        return false;
    }
}
