import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

    constructor(private $auth: AuthService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.$auth.isAuthenticated()) {
            this.$auth.logout();
            return false;
        }
        return true;
    }

    canLoad(route) {
        if (this.$auth.isAuthenticated()) {
            this.router.navigate(['/']);
        }
        return true;
    }
}
